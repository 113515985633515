import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex: 1 auto;
  align-items: stretch;
  flex-direction: row;
  max-height: 100vh;
  overflow: auto;
  position: relative;
`
