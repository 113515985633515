import React, { useState } from 'react'
import { Button, Input, Icon } from '@globalhealthmonitor/components'
import { useForm, SubmitHandler } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { useSnackbar } from 'notistack'

import { forgotPassword } from 'services/authenticate'

import Link from 'components/Link'
import AuthContainer from '../../components/AuthContainer'

type FormValues = {
  email: string
}

const ForgotPassword: React.FC = () => {
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const { register, handleSubmit } = useForm<FormValues>()
  const { enqueueSnackbar } = useSnackbar()

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    setLoading(true)
    const result = await forgotPassword(data.email)

    if (result.data) {
      enqueueSnackbar('E-mail de recuperação enviado com sucesso', {
        variant: 'success'
      })

      history.push(`/recover-password?email=${data.email}`)
    } else {
      enqueueSnackbar(result.message, { variant: 'error' })
    }

    setLoading(false)
  }

  return (
    <AuthContainer>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Link href="/login" icon="arrow_back" color="textSecondary">
          Voltar para login
        </Link>
        <Input
          name="email"
          label="Email"
          type="email"
          required
          icon={<Icon>email</Icon>}
          inputRef={register}
        />
        <Button
          loading={loading}
          type="submit"
          variant="contained"
          color="primary"
          size="large"
          fullWidth
        >
          Recuperar senha
        </Button>
      </form>
    </AuthContainer>
  )
}

export default ForgotPassword
