import React from 'react'
import { Button } from '@globalhealthmonitor/components'
import { Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'

import { Container, SuperTitle } from './styles'

const Error404: React.FC = () => (
  <Container>
    <SuperTitle variant="h1" color="textSecondary">
      Erro 404
    </SuperTitle>
    <Typography variant="body2" color="textSecondary">
      O conteúdo que você está procurando não existe ou não está mais
      disponível.
    </Typography>
    <Link to="/">
      <Button variant="contained" color="primary" type="button">
        Voltar para o início
      </Button>
    </Link>
  </Container>
)

export default Error404
