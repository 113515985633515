import {
  List,
  ListItem,
  Avatar,
  ListProps,
  ListItemProps,
  AvatarProps
} from '@material-ui/core'

import styled, { css } from 'styled-components'

export const Container = styled.div`
  display: flex;
  min-height: 100vh;
  width: 100vw;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${(props) => props.theme.breakpoints.up('md')} {
    flex-direction: row;
  }
`

export const FormContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${(props) => props.theme.spacing(3)}px;

  ${(props) => props.theme.breakpoints.up('md')} {
    min-width: ${(props) => props.theme.spacing(70)}px;
    max-width: 40%;
  }

  ${(props) => props.theme.breakpoints.down('md')} {
    min-height: 100vh;
  }

  & > div {
    width: 100%;
    max-width: ${(props) => props.theme.spacing(50)}px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: ${(props) => props.theme.spacing(3)}px;
  }

  .title {
    margin-bottom: ${(props) => props.theme.spacing(2)}px;
  }
`

export const LogoContainer = styled.div`
  margin-bottom: ${(props) => props.theme.spacing(7)}px;
  text-align: center;
`

export const PasswordGroup = styled.div`
  display: flex;
  flex-direction: column;
`

export const ForgotPassword = styled.span`
  ${(props) => {
    const linkStyle = props.theme.typography.ghmButton.link as Record<
      string,
      string | number
    >
    return css({ ...linkStyle })
  }};
`

export const ImageContainer = styled.div`
  display: flex;
  flex-grow: 1;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background: url('/images/intersect.png') no-repeat;
  background-size: cover;
  background-position: right;
  background-color: ${(props) => props.theme.palette.accent.secondary};
  padding: ${(props) => props.theme.spacing(3)}px;

  & > div {
    max-width: ${(props) => props.theme.spacing(53)}px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: ${(props) => props.theme.spacing(0, 3)};
  }

  img {
    max-width: calc(100% + ${(props) => props.theme.spacing(6)}px);
    margin-bottom: ${(props) => props.theme.spacing(3)}px;
    margin: ${(props) => props.theme.spacing(0, -3)};
  }

  .title {
    margin-bottom: ${(props) => props.theme.spacing(3)}px;
    color: ${(props) =>
      props.theme.palette.getContrastText(
        props.theme.palette.accent.secondary
      )};
    line-height: 1.5;
  }

  .read-more {
    color: #04a5ff;
  }
`

export const ProfileContainer = styled.div`
  display: flex;
  min-height: 100vh;
  width: 100vw;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
`

export const FormProfileContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${(props) => props.theme.spacing(3)}px;
  flex-grow: 1;
`
export const ListProfileContainer = styled(List)<ListProps>`
  box-sizing: border-box;
  border-radius: 4px;
  padding-top: 0;
  padding-bottom: 0;
  margin-top: ${(props) => props.theme.spacing(2)}px;
  border: ${(props) => `1px solid ${props.theme.palette.divider}`};
`

export const ListItemProfileContainer = styled(ListItem)<ListItemProps>`
  display: flex;
  flex-direction: row;
  border-radius: 0px;
  min-width: 418px;
  background-color: ${(props) => props.theme.palette.background.paper};
`

export const ProfileIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: ${(props) => props.theme.spacing(0.5)}px;
  padding-left: ${(props) => props.theme.spacing(2)}px;
`

export const ButtonProfileBack = styled.button`
  display: flex;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  cursor: pointer;
  background: none;
  color: ${(props) => props.theme.palette.text.secondary};
`

export const FooterContainer = styled.footer`
  display: flex;
  margin: auto auto 2% auto;
`

export const AvatarProfile = styled(Avatar)<AvatarProps>`
  color: ${(props) => props.theme.palette.common.white};
  background-color: ${(props) =>
    props.src ? 'transparent' : props.theme.palette.success.dark};
`
