import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Loader } from '@globalhealthmonitor/components'

import { readCurrentDetails } from 'services/authenticate'
import Role from 'types/Role'
import Company from 'types/Company'
import User from 'types/User'

import AuthContext from './Context'
import { Container } from './styles'

const AuthProvider: React.FC = ({ children }) => {
  const history = useHistory()
  const [loading, setLoading] = useState(true)
  const [user, setUser] = useState<User>()
  const [role, setRole] = useState<Role>()
  const [company, setCompany] = useState<Company>()

  const getData = async () => {
    setLoading(true)

    const response = await readCurrentDetails()

    if (response.data) {
      setUser(response.data.user)
      setRole(response.data.role)
      setCompany(response.data.company)
    } else {
      localStorage.removeItem('@ghm:token')
      history.push('/login')
    }

    setLoading(false)
  }

  useEffect(() => {
    getData()
  }, [])

  const propsProvider = {
    user,
    isPermission: (permission) =>
      role && role.permissions.some((item) => item === permission),

    onLogout: () => {
      localStorage.removeItem('@ghm:token')
      history.push('/login')
    },
    updateState: () => getData(),
    company
  }

  return (
    <AuthContext.Provider value={propsProvider}>
      <Container>{loading ? <Loader full /> : children}</Container>
    </AuthContext.Provider>
  )
}

export default AuthProvider
