import React from 'react'
import { Box, Icon, Menu } from '@material-ui/core'

import ColumnAction from 'types/ColumnAction'

import { ActionMenuItem } from './styles'

export interface IDataTableProps {
  actions?: ColumnAction[]
  anchorEl?: HTMLElement
  onClose(): void
  actionRow?: any
}

const ItemSubMenu: React.FC<IDataTableProps> = ({
  actions,
  anchorEl,
  onClose,
  actionRow
}) => {
  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
    >
      {actions.map((action) => (
        <ActionMenuItem
          key={
            typeof action.key === 'string' ? action.key : action.key(actionRow)
          }
          onClick={() => action.onClick(actionRow)}
        >
          <Box
            color={
              typeof action.color === 'string'
                ? action.color || 'secondary.main'
                : action.color(actionRow)
            }
            className="item-container"
          >
            {action.icon && (
              <Icon className="icon" fontSize="small">
                {typeof action.icon === 'string'
                  ? action.icon
                  : action.icon(actionRow)}
              </Icon>
            )}
            <span className="text">
              {typeof action.label === 'string'
                ? action.label
                : action.label(actionRow)}
            </span>
          </Box>
        </ActionMenuItem>
      ))}
    </Menu>
  )
}

export default ItemSubMenu
