import { lazy } from 'react'
import ModuleRoute from 'types/ModuleRoute'

const modules: ModuleRoute[] = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    menu: true,
    icon: 'dashboard',
    component: lazy(() => import('../modules/dashboard')),
    permission: 'dashboard',
    path: '/dashboard'
  },
  {
    id: 'company',
    title: 'Empresa',
    menu: true,
    icon: 'domain',
    component: lazy(() => import('../modules/company')),
    permission: 'read_irregularity',
    path: '/company'
  },
  {
    id: 'collaborators',
    title: 'Colaboradores',
    menu: true,
    icon: 'group',
    component: lazy(() => import('../modules/collaborator')),
    permission: 'crud_employee',
    path: '/collaborators'
  },
  {
    id: 'units',
    title: 'Unidades',
    menu: true,
    icon: 'home',
    component: lazy(() => import('../modules/unit')),
    permission: 'crud_units',
    path: '/units'
  },
  {
    id: 'sectors',
    title: 'Setores',
    menu: true,
    icon: 'bubble_chart',
    component: lazy(() => import('../modules/sector')),
    permission: 'crud_sector',
    path: '/sectors'
  },
  {
    id: 'reports',
    title: 'Relatórios',
    menu: true,
    icon: 'description',
    component: lazy(() => import('../modules/report')),
    permission: 'reports',
    path: '/reports'
  },
  {
    id: 'news',
    title: 'Notícias',
    menu: true,
    icon: 'rss_feed',
    component: lazy(() => import('../modules/news')),
    permission: 'crud_news',
    path: '/news'
  },
  {
    id: 'scheduleExam',
    title: 'Agendamento de exames',
    menu: true,
    icon: 'calendar_month',
    component: lazy(() => import('../modules/scheduleExam')),
    path: '/schedule-exam'
  },
  {
    id: 'profile',
    title: 'Dados de acesso',
    menu: false,
    icon: 'person',
    component: lazy(() => import('../modules/currentUser')),
    path: '/profile'
  }
]

export default modules
