import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Icon, Tooltip } from '@material-ui/core'

import { useAuth } from 'provider/AuthProvider'

import { MainMenuSquareButton } from '../styles'

interface IProps {
  exact?: boolean
  href?: string
  icon: string
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  permission?: string
  tooltipTitle?: string
}

const MenuLink: React.FC<IProps> = ({
  exact = false,
  href = '',
  icon,
  onClick,
  permission,
  tooltipTitle,
  ...props
}) => {
  const { isPermission } = useAuth()
  const location = useLocation()

  if (permission && !isPermission(permission)) {
    return null
  }

  const isActive = exact
    ? location.pathname === href
    : location.pathname.indexOf(href) !== -1

  if (href) {
    return (
      <Link to={href} {...props}>
        <Tooltip title={tooltipTitle} placement="right" arrow>
          <MainMenuSquareButton $active={isActive}>
            <Icon color="inherit">{icon}</Icon>
          </MainMenuSquareButton>
        </Tooltip>
      </Link>
    )
  } else {
    return (
      <MainMenuSquareButton $active={false} onClick={onClick}>
        <Icon color="inherit">{icon}</Icon>
      </MainMenuSquareButton>
    )
  }
}

export default MenuLink
