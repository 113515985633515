import { useContext, useEffect, useState } from 'react'

import AuthContext from './Context'
import IAuthProps from './IAuthProps'

function useAuth(): IAuthProps {
  const context = useContext(AuthContext)
  const [data, setData] = useState<IAuthProps>(context)

  useEffect(() => setData(context), [context])

  return data
}

export default useAuth
