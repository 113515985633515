import api from './api'
import Profile from 'types/Profile'
import User from 'types/User'
import Access from 'types/Access'
import Response from 'entities/Response'

export const getRoles = async (): Promise<Response<Profile[]>> => {
  try {
    const response = await api.get('/roles/me')

    return Response.fromData<Profile[]>(
      response.data.roles.filter(
        (role) => role.key === 'company_admin'
      ) as Profile[],
      response.status
    )
  } catch (e) {
    return Response.fromError(e)
  }
}

export const setRole = async (
  roleId: string,
  companyId: string
): Promise<Response<string>> => {
  try {
    const response = await api.post('/user/role', {
      role: roleId,
      company: companyId
    })

    return Response.fromData<string>(response.data.token, response.status)
  } catch (e) {
    return Response.fromError(e)
  }
}

export const updateCurrentUser = async (
  user: Record<string, any>
): Promise<Response<User>> => {
  try {
    const response = await api.put('/users', user)

    return Response.fromData<User>(response.data.user as User, response.status)
  } catch (e) {
    return Response.fromError(e)
  }
}

export const resetUserAccess = async (
  user: User
): Promise<Response<string>> => {
  try {
    const response = await api.post('/access', {
      userId: user.id,
      email: user.email
    })

    return Response.fromData<string>(response.data, response.status)
  } catch (e) {
    return Response.fromError(e)
  }
}

export const getUserAccessHistory = async (
  userId: string
): Promise<Response<Access[]>> => {
  try {
    const response = await api.get(`/access?userId=${userId}`)

    return Response.fromData<Access[]>(
      response.data as Access[],
      response.status
    )
  } catch (e) {
    return Response.fromError(e)
  }
}
