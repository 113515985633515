import React, { useState } from 'react'
import { Button, Input, Icon } from '@globalhealthmonitor/components'
import { useForm, SubmitHandler } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { useSnackbar } from 'notistack'

import { changePassword, sendValidationEmail } from 'services/authenticate'

import AuthContainer from '../../components/AuthContainer'

type FormValues = {
  password: string
  confirmPassword: string
}

const SetPassword: React.FC = () => {
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const { register, handleSubmit } = useForm<FormValues>()
  const { enqueueSnackbar } = useSnackbar()

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    setLoading(true)
    if (data.password === data.confirmPassword) {
      const result = await changePassword(data.password)

      if (result.data) {
        if (result.data.active) {
          enqueueSnackbar(
            'Senha alterada com sucesso, faça o login novamente',
            { variant: 'success' }
          )
          history.push('/login')
        } else {
          sendValidationEmail()
          enqueueSnackbar(
            'Sua senha foi alterada com sucesso, porém seu usuário não está ativado. Vamos agora validar seu usuário.',
            { variant: 'warning' }
          )
          history.push('/validate')
        }
      } else {
        enqueueSnackbar(result.message, { variant: 'error' })
      }
    } else {
      enqueueSnackbar(
        'A senha de confirmação deve ser igual a senha digitada anteriormente',
        { variant: 'error' }
      )
    }

    setLoading(false)
  }

  return (
    <AuthContainer title="Este é seu primeiro acesso, informe sua nova senha:">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          name="password"
          label="Nova senha"
          type="password"
          required
          icon={<Icon>lock</Icon>}
          inputRef={register}
        />
        <Input
          name="confirmPassword"
          label="Confirmar senha"
          type="password"
          required
          icon={<Icon>lock</Icon>}
          inputRef={register}
        />
        <Button
          loading={loading}
          type="submit"
          variant="contained"
          color="primary"
          size="large"
          fullWidth
        >
          Atualizar senha
        </Button>
      </form>
    </AuthContainer>
  )
}

export default SetPassword
