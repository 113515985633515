import React from 'react'
import { useHistory } from 'react-router-dom'

import { useAuth } from 'provider/AuthProvider'

import MenuLink from './MenuLink'
import ItemSubMenu from '../../../components/ItemSubmenu'

const MenuSettings: React.FC = () => {
  const { onLogout } = useAuth()
  const history = useHistory()
  const [
    anchorElMenuActions,
    setAnchorElMenuActions
  ] = React.useState<null | HTMLElement>(null)

  const handleOpenMenuActions = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorElMenuActions(event.currentTarget)
  }

  const handleCloseMenuActions = () => {
    setAnchorElMenuActions(null)
  }
  return (
    <>
      <MenuLink onClick={(e) => handleOpenMenuActions(e)} icon="settings" />
      <ItemSubMenu
        onClose={() => handleCloseMenuActions()}
        anchorEl={anchorElMenuActions}
        actions={[
          {
            key: 'edit-profile',
            label: 'Minha conta',
            icon: 'account_box_outline',
            color: 'secondary.main',
            onClick: () => history.push('/profile/edit')
          },
          {
            key: 'change-access-profile',
            label: 'Trocar perfil de acesso',
            icon: 'admin_panel_settings',
            color: 'secondary.main',
            onClick: () => history.push('/profile')
          },
          {
            key: 'logout',
            label: 'Sair',
            icon: 'exit_to_app',
            color: 'secondary.main',
            onClick: () => onLogout()
          }
        ]}
      ></ItemSubMenu>
    </>
  )
}

export default MenuSettings
