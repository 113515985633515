import React from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import { GhmProvider } from '@globalhealthmonitor/components'

import Login from 'modules/auth/pages/Login'
import ForgotPassword from 'modules/auth/pages/ForgotPassword'
import RecoverPassword from 'modules/auth/pages/RecoverPassword'
import Error404 from 'modules/errors/Error404'
import Error403 from 'modules/errors/Error403'
import AuthRouter from './AuthRouter'
import SetPassword from 'modules/auth/pages/SetPassword'

const App: React.FC = () => (
  <GhmProvider>
    <BrowserRouter>
      <Switch>
        <Route path="/login" exact component={Login} />
        <Route path="/forgot-password" exact component={ForgotPassword} />
        <Route path="/recover-password" exact component={RecoverPassword} />
        <Route path="/set-password" exact component={SetPassword} />
        <Route path="/404" exact component={Error404} />
        <Route path="/403" exact component={Error403} />
        <Route path="*" exact component={AuthRouter} />
        <Redirect to="/404" />
      </Switch>
    </BrowserRouter>
  </GhmProvider>
)

export default App
