import React from 'react'

const Logo: React.FC = () => (
  <svg
    width="42"
    style={{ height: 'auto' }}
    viewBox="0 0 405 473"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M202.17 101.22C230.099 101.22 252.74 78.579 252.74 50.65C252.74 22.7209 230.099 0.0799561 202.17 0.0799561C174.241 0.0799561 151.6 22.7209 151.6 50.65C151.6 78.579 174.241 101.22 202.17 101.22Z"
        fill="#1335A3"
      />
      <path
        d="M351.84 91.27C344.61 99.1709 336.769 106.49 328.39 113.16C295.05 141.74 248.84 156.34 202.39 156.44C156 156.34 109.79 141.74 76.45 113.16C68.0685 106.493 60.2273 99.1734 53 91.27C47 84.75 36.3 85.39 31.73 92.96C5.39998 136.5 -2.46001 178.08 0.629985 216.46H125.05C127.508 216.457 129.919 217.125 132.025 218.392C134.131 219.659 135.851 221.477 137 223.65L167.88 282.17C168.647 283.623 169.798 284.839 171.207 285.685C172.616 286.531 174.23 286.975 175.873 286.969C177.517 286.963 179.127 286.506 180.53 285.65C181.932 284.793 183.074 283.569 183.83 282.11L236.44 180.39C237.156 179.008 238.218 177.835 239.523 176.987C240.827 176.138 242.33 175.642 243.883 175.547C245.437 175.452 246.989 175.762 248.387 176.446C249.785 177.13 250.981 178.165 251.86 179.45L273.07 210.57C274.31 212.384 275.973 213.868 277.916 214.894C279.859 215.921 282.023 216.458 284.22 216.46H403.75C406.69 178.21 399.14 136.53 373.1 92.96C368.57 85.37 357.81 84.75 351.84 91.27Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M284.2 216.46C282.002 216.461 279.838 215.925 277.894 214.898C275.951 213.871 274.288 212.386 273.05 210.57L251.83 179.45C250.951 178.168 249.755 177.135 248.358 176.452C246.961 175.77 245.411 175.461 243.859 175.555C242.307 175.65 240.806 176.145 239.502 176.992C238.199 177.84 237.137 179.01 236.42 180.39L183.8 282.08C183.043 283.537 181.902 284.758 180.5 285.613C179.099 286.468 177.49 286.923 175.848 286.929C174.206 286.935 172.594 286.492 171.186 285.648C169.778 284.804 168.628 283.591 167.86 282.14L137 223.65C135.849 221.468 134.122 219.644 132.006 218.376C129.89 217.109 127.467 216.446 125 216.46H0.630005C8.07 308.91 87.38 387.51 193.81 469.08C196.189 470.645 198.882 471.669 201.7 472.08C202.162 472.13 202.628 472.13 203.09 472.08C205.908 471.671 208.602 470.647 210.98 469.08C317.77 387.62 396.6 309.41 403.75 216.46H284.2Z"
        fill="url(#paint1_linear)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="202.17"
        y1="286.97"
        x2="202.17"
        y2="86.79"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#051542" />
        <stop offset="0.6" stopColor="#1334A0" />
        <stop offset="1" stopColor="#1335A3" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="202.19"
        y1="175.52"
        x2="202.19"
        y2="472.09"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#009BFF" />
        <stop offset="0.72" stopColor="#0046FF" />
      </linearGradient>
      <clipPath id="clip0">
        <rect
          width="404.35"
          height="472.01"
          fill="white"
          transform="translate(0 0.0799561)"
        />
      </clipPath>
    </defs>
  </svg>
)

export default Logo
