import React, { useState } from 'react'
import { Button, Input, Icon } from '@globalhealthmonitor/components'
import { useForm, SubmitHandler } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { useSnackbar } from 'notistack'

import Link from 'components/Link'

import { login } from 'services/authenticate'
import AuthContainer from '../../components/AuthContainer'

type FormValues = {
  email: string
  password: string
}

const Login: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const { register, handleSubmit } = useForm<FormValues>()
  const { enqueueSnackbar } = useSnackbar()

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    setLoading(true)
    const response = await login(data.email, data.password)

    if (response.data) {
      switch (response.statusCode) {
        case 203:
          localStorage.setItem('@ghm:subtoken', response.data.token)
          history.push('/validate')
          break
        case 206:
          localStorage.setItem('@ghm:subtoken', response.data.token)
          history.push('/set-password')
          break
        case 200:
          localStorage.setItem('@ghm:token', response.data.token)
          enqueueSnackbar('Login realizado com sucesso', { variant: 'success' })
          history.push('/profile')
          break
        default:
          break
      }
    } else {
      enqueueSnackbar(response.message, { variant: 'error' })
    }

    setLoading(false)
  }

  return (
    <AuthContainer title="Faça seu login para acessar:">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          name="email"
          label="Email"
          type="email"
          required
          icon={<Icon>email</Icon>}
          inputRef={register}
        />
        <Input
          name="password"
          label="Senha"
          type="password"
          required
          icon={<Icon>lock</Icon>}
          inputRef={register}
        />
        <Link color="textPrimary" href="/forgot-password">
          Esqueci minha senha
        </Link>
        <Button
          loading={loading}
          type="submit"
          variant="contained"
          color="primary"
          size="large"
          fullWidth
        >
          Entrar
        </Button>
      </form>
    </AuthContainer>
  )
}

export default Login
