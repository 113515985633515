import React from 'react'
import { Button, Icon, Typography } from '@material-ui/core'
import { LogoSlogan } from '@globalhealthmonitor/components'
import {
  Container,
  FormContainer,
  ImageContainer,
  LogoContainer
} from './styles'

const AuthContainer: React.FC<{ title?: string }> = ({ children, title }) => (
  <Container>
    <FormContainer>
      <div>
        <LogoContainer>
          <LogoSlogan />
        </LogoContainer>
        {title && (
          <Typography variant="body1" color="textSecondary" className="title">
            <b>{title}</b>
          </Typography>
        )}
        {children}
      </div>
    </FormContainer>

    <ImageContainer>
      <div>
        <Typography variant="h1" className="title">
          Portal Admin Empresa
        </Typography>
        <img src="/images/login-info.png" />
        <Typography variant="h2" className="title">
          Organize e monitore o estado de saúde de todos os colaboradores da sua
          empresa.
        </Typography>
        <Button
          color="primary"
          variant="text"
          endIcon={<Icon color="inherit">chevron_right</Icon>}
          className="read-more"
          onClick={() => window.open('http://www.ghm.world/', '_blank')}
        >
          Saiba mais
        </Button>
      </div>
    </ImageContainer>
  </Container>
)

export default AuthContainer
