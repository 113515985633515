import styled from 'styled-components'
import { Link, Icon } from '@material-ui/core'

export const StyledLink = styled(Link)`
  align-items: center;
  display: flex;

  :hover {
    text-decoration: none;
    opacity: 0.8;
    cursor: pointer;
  }
`

export const LinkContent = styled.span`
  align-items: center;
  display: flex;
`

export const StyledIcon = styled(Icon)`
  margin-right: ${(props) => props.theme.spacing(2)}px;
`
