import React, { useState } from 'react'
import { Button, Icon, Input } from '@globalhealthmonitor/components'
import { useForm, SubmitHandler } from 'react-hook-form'
import { useHistory, useLocation } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import queryString from 'query-string'

import {
  forgotPasswordResend,
  forgotPasswordValidate
} from 'services/authenticate'

import AuthContainer from '../../components/AuthContainer'

type FormValues = {
  token: string
}

const RecoverPassword: React.FC = () => {
  const history = useHistory()
  const location = useLocation()
  const [loading, setLoading] = useState(false)
  const [loadingSendEmail, setLoadingSendEmail] = useState(false)
  const { register, handleSubmit } = useForm<FormValues>()
  const { enqueueSnackbar } = useSnackbar()

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    setLoading(true)
    const params = queryString.parse(location.search)
    const result = await forgotPasswordValidate(
      params.email as string,
      data.token
    )

    if (result.data) {
      localStorage.setItem('@ghm:subtoken', result.data)
      enqueueSnackbar('Código de recuperação validado com sucesso', {
        variant: 'success'
      })
      history.push('/set-password')
    } else {
      enqueueSnackbar(
        'Código de recuperação inválido, utilize o código enviado para o seu email',
        { variant: 'error' }
      )
    }

    setLoading(false)
  }

  const handleSendEmail = async () => {
    setLoadingSendEmail(true)
    const params = queryString.parse(location.search)
    const result = await forgotPasswordResend(params.email as string)

    if (result.data) {
      enqueueSnackbar('E-mail de recuperação enviado com sucesso', {
        variant: 'success'
      })
    } else {
      enqueueSnackbar(result.message, { variant: 'error' })
    }

    setLoadingSendEmail(false)
  }

  return (
    <AuthContainer title="Informe o código de recuperação enviado no seu endereço de email:">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          name="token"
          label="Código de recuperação"
          required
          inputProps={{ maxLength: 5 }}
          icon={<Icon>keyboard</Icon>}
          inputRef={register}
        />
        <Button
          loading={loadingSendEmail}
          onClick={() => handleSendEmail()}
          variant="text"
          color="primary"
          size="small"
          fullWidth
        >
          Enviar código novamente
        </Button>
        <Button
          loading={loading}
          type="submit"
          variant="contained"
          color="primary"
          size="large"
          fullWidth
        >
          Validar código
        </Button>
      </form>
    </AuthContainer>
  )
}

export default RecoverPassword
