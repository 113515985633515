import api, { apiNoAuth } from './api'
import Response from 'entities/Response'
import User from 'types/User'
import Company from 'types/Company'
import Role from 'types/Role'

export const login = async (
  email: string,
  password: string
): Promise<Response<{ user: User; token: string }>> => {
  try {
    const response = await apiNoAuth.post('/login', { email, password })

    return Response.fromData<{ user: User; token: string }>(
      { user: response.data.user as User, token: response.data.token },
      response.status,
      response.data.message
    )
  } catch (e) {
    return Response.fromError(e)
  }
}

export const sendValidationEmail = async (): Promise<Response> => {
  try {
    const response = await apiNoAuth.post('/user/email/validate-user', {})
    return Response.fromData(
      response.data,
      response.status,
      response.data.message
    )
  } catch (e) {
    return Response.fromError(e)
  }
}

export const changePassword = async (
  password: string
): Promise<Response<User>> => {
  try {
    const response = await apiNoAuth.post('user/change-password', { password })

    return Response.fromData<User>(response.data.user as User, response.status)
  } catch (e) {
    return Response.fromError(e)
  }
}

export const forgotPassword = async (email: string): Promise<Response> => {
  try {
    const response = await apiNoAuth.post('forgot-password', { email })

    return Response.fromData(
      response.data,
      response.status,
      response.data.message
    )
  } catch (e) {
    return Response.fromError(e)
  }
}

export const forgotPasswordValidate = async (
  email: string,
  token: string
): Promise<Response<string>> => {
  try {
    const response = await apiNoAuth.post('/forgot-password/validate', {
      email,
      token
    })

    return Response.fromData<string>(response.data.token, response.status)
  } catch (e) {
    return Response.fromError(e)
  }
}

export const forgotPasswordResend = async (
  email: string
): Promise<Response> => {
  try {
    const response = await apiNoAuth.post('forgot-password/resend', { email })

    return Response.fromData(
      response.data,
      response.status,
      response.data.message
    )
  } catch (e) {
    return Response.fromError(e)
  }
}

export const validateUser = async (token: string): Promise<Response<User>> => {
  try {
    const response = await apiNoAuth.post('/user/validate', { token })

    return Response.fromData<User>(response.data as User, response.status)
  } catch (e) {
    return Response.fromError(e)
  }
}

export const readCurrentDetails = async (): Promise<
  Response<{ user: User; role: Role; company?: Company }>
> => {
  try {
    const response = await api.get('/me')

    return Response.fromData(
      {
        user: response.data.user as User,
        role: response.data.role as Role,
        company: response.data.company as Company
      },
      response.status
    )
  } catch (e) {
    return Response.fromError(e)
  }
}
