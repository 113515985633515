import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  min-height: 100vh;
  width: 100vw;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${(props) => props.theme.breakpoints.up('md')} {
    flex-direction: row;
  }
`

export const FormContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${(props) => props.theme.spacing(3)}px;

  ${(props) => props.theme.breakpoints.up('md')} {
    min-width: ${(props) => props.theme.spacing(70)}px;
    max-width: 40%;
  }

  ${(props) => props.theme.breakpoints.down('md')} {
    min-height: 100vh;
  }

  & > div {
    width: 100%;
    max-width: ${(props) => props.theme.spacing(50)}px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: ${(props) => props.theme.spacing(3)}px;
  }

  .title {
    margin-bottom: ${(props) => props.theme.spacing(2)}px;
  }
`

export const LogoContainer = styled.div`
  margin-bottom: ${(props) => props.theme.spacing(7)}px;
  text-align: center;
`

export const ImageContainer = styled.div`
  display: flex;
  flex-grow: 1;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background: url('/images/intersect.png') no-repeat;
  background-size: cover;
  background-position: right;
  background-color: ${(props) => props.theme.palette.accent.secondary};
  padding: ${(props) => props.theme.spacing(3)}px;

  & > div {
    max-width: ${(props) => props.theme.spacing(53)}px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: ${(props) => props.theme.spacing(0, 3)};
  }

  img {
    max-width: calc(100% + ${(props) => props.theme.spacing(6)}px);
    margin-bottom: ${(props) => props.theme.spacing(3)}px;
    margin: ${(props) => props.theme.spacing(0, -3)};
  }

  .title {
    margin-bottom: ${(props) => props.theme.spacing(3)}px;
    color: ${(props) =>
      props.theme.palette.getContrastText(
        props.theme.palette.accent.secondary
      )};
    line-height: 1.5;
  }

  .read-more {
    color: #04a5ff;
  }
`
