import styled from 'styled-components'
import { fade, IconButton, IconButtonProps } from '@material-ui/core'

export const MenuContainer = styled.div`
  display: none;
  height: 100vh;
  width: ${(props) => props.theme.spacing(12)}px;
  min-width: ${(props) => props.theme.spacing(12)}px;
  margin-right: -9px;

  ${(props) => props.theme.breakpoints.up('sm')} {
    display: flex;
  }
`

export const MainMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${(props) => props.theme.spacing(11)}px;
  padding: ${(props) => props.theme.spacing(6, 0, 3)};
  border-radius: 0 12px 12px 0;
  background: ${(props) => props.theme.palette.primary.contrastText};
  box-shadow: ${(props) => props.theme.shadows[20]};

  & > div {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .exit {
    opacity: 0.7;
  }
`

export const MainMenuItens = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  gap: ${(props) => props.theme.spacing(1)}px;
`

interface IPropsMenuSquareButton extends IconButtonProps {
  $active?: boolean
}

export const MainMenuSquareButton = styled(IconButton).attrs({
  disableRipple: true
} as IPropsMenuSquareButton)`
  border-radius: ${(props) => props.theme.spacing(3 / 2)}px;
  width: ${(props) => props.theme.spacing(6)}px;
  height: ${(props) => props.theme.spacing(6)}px;
  transition: ${(props) =>
    props.theme.transitions.create(['color', 'background'])};
  background: ${(props) =>
    props.$active
      ? fade(props.theme.palette.primary.main, 0.08)
      : 'transparent'};
  color: ${(props) =>
    props.$active
      ? props.theme.palette.primary.main
      : props.theme.palette.text.hint};

  &:hover {
    background: ${(props) =>
      props.$active
        ? fade(props.theme.palette.primary.main, 0.2)
        : fade(props.theme.palette.text.hint, 0.2)};
  }
`
