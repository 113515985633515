import React from 'react'
import { Link } from 'react-router-dom'

import ModuleRoute from 'types/ModuleRoute'

import Logo from 'components/icons/Logo'
import MenuLink from './components/MenuLink'
import MenuSettings from './components/MenuSettings'

import { MainMenuContainer, MainMenuItens, MenuContainer } from './styles'

interface IMenuProps {
  modules: ModuleRoute[]
}

const Menu: React.FC<IMenuProps> = ({ modules }) => (
  <MenuContainer>
    <MainMenuContainer>
      <div>
        <Link to="/">
          <Logo />
        </Link>
        <MainMenuItens>
          {modules.map((module) => {
            if (module.menu) {
              return (
                <MenuLink
                  key={module.path}
                  href={module.path}
                  icon={module.icon}
                  permission={module.permission}
                  tooltipTitle={module.title}
                />
              )
            }

            return <div key={module.path} />
          })}
        </MainMenuItens>
        <MenuSettings />
      </div>
    </MainMenuContainer>
  </MenuContainer>
)

export default Menu
