import React, { Suspense } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { AuthProvider } from 'provider/AuthProvider'
import Menu from 'containers/Menu'
import SetPassword from 'modules/auth/pages/SetPassword'
import Validate from 'modules/auth/pages/Validate'
import Profile from 'modules/auth/pages/Profile'
import modules from './modules'

const AuthRouter: React.FC = () => (
  <AuthProvider>
    <Switch>
      <Route path="/set-password" exact component={SetPassword} />
      <Route path="/validate" exact component={Validate} />
      <Route path="/profile" exact component={Profile} />
      <Route path="*">
        <Menu modules={modules} />
        <Suspense fallback={<div />}>
          <Switch>
            <Route path="/" exact>
              <Redirect to="/dashboard" />
            </Route>
            {modules.map((module) => (
              <Route
                key={module.path}
                path={module.path}
                component={module.component}
                exact={false}
              />
            ))}
            <Redirect to="/404" />
          </Switch>
        </Suspense>
      </Route>
    </Switch>
  </AuthProvider>
)

export default AuthRouter
