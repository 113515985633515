import styled from 'styled-components'
import { Typography } from '@material-ui/core'

export const Container = styled.div`
  display: flex;
  height: 100vh;
  margin-left: ${(props) => props.theme.spacing(12)}px;
  justify-content: center;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing(3)}px;
`

export const SuperTitle = styled(Typography)`
  font-size: ${(props) => props.theme.typography.pxToRem(36)};
`
