import React, { useState } from 'react'
import { Button, Input, Icon } from '@globalhealthmonitor/components'
import { useForm, SubmitHandler } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { useSnackbar } from 'notistack'

import { validateUser, sendValidationEmail } from 'services/authenticate'

import AuthContainer from '../../components/AuthContainer'

type FormValues = {
  token: string
}

const Validate: React.FC = () => {
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [loadingSendToken, setLoadingSendToken] = useState(false)
  const { register, handleSubmit } = useForm<FormValues>()
  const { enqueueSnackbar } = useSnackbar()

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    setLoading(true)
    const result = await validateUser(data.token)

    if (result.data) {
      enqueueSnackbar('Usuário ativado com sucesso, faça o login novamente', {
        variant: 'success'
      })
      history.push('/login')
    } else {
      enqueueSnackbar('Código de ativação inválido', { variant: 'error' })
    }

    setLoading(false)
  }

  const handleSendToken = async () => {
    setLoadingSendToken(true)
    const result = await sendValidationEmail()

    if (result.data) {
      enqueueSnackbar('Novo código de ativação enviado', { variant: 'success' })
    } else {
      enqueueSnackbar('Ocorreu um erro ao enviar o novo código de ativação', {
        variant: 'error'
      })
    }

    setLoadingSendToken(false)
  }

  return (
    <AuthContainer title="Digite o código de ativação com 6 dígitos enviado no endereço de email para confirmar seu cadastro:">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          name="token"
          label="Código de ativação"
          required
          inputProps={{ maxLength: 6 }}
          icon={<Icon>keyboard</Icon>}
          inputRef={register}
        />
        <Button
          loading={loadingSendToken}
          onClick={() => handleSendToken()}
          variant="text"
          color="primary"
          size="small"
          fullWidth
        >
          Enviar email novamente
        </Button>
        <Button
          loading={loading}
          type="submit"
          variant="contained"
          color="primary"
          size="large"
          fullWidth
        >
          Validar usuário
        </Button>
      </form>
    </AuthContainer>
  )
}

export default Validate
