import React from 'react'
import { Link } from 'react-router-dom'
import { StyledLink, StyledIcon, LinkContent } from './styles'

type IProps = {
  icon?: string
  href: string
  color:
    | 'inherit'
    | 'error'
    | 'textSecondary'
    | 'primary'
    | 'secondary'
    | 'textPrimary'
    | 'initial'
}

const LinkComponent: React.FC<IProps> = ({
  icon,
  children,
  href,
  ...props
}) => (
  <Link to={href}>
    <StyledLink {...props}>
      <LinkContent>
        {icon && <StyledIcon>{icon}</StyledIcon>}
        {children}
      </LinkContent>
    </StyledLink>
  </Link>
)

export default LinkComponent
