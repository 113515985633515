import React, { useState, useEffect } from 'react'
import { Loader, LogoSlogan } from '@globalhealthmonitor/components'
import { useHistory } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import {
  Typography,
  ListItemText,
  ListItemAvatar,
  Divider
} from '@material-ui/core'
import { ChevronRight, ChevronLeft } from '@material-ui/icons'

import { useAuth } from 'provider/AuthProvider'
import { getRoles, setRole } from 'services/user'
import Profile from 'types/Profile'

import {
  ListProfileContainer,
  ListItemProfileContainer,
  FormProfileContainer,
  ProfileIconContainer,
  LogoContainer,
  ProfileContainer,
  FooterContainer,
  ButtonProfileBack,
  AvatarProfile
} from './styles'

const ProfilePage: React.FC = () => {
  const [profiles, setProfiles] = useState([])
  const [loading, setLoading] = useState(true)
  const { updateState } = useAuth()
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    const fetchData = async () => {
      const response = await getRoles()

      if (response.data) {
        setProfiles(response.data)
      } else {
        enqueueSnackbar(response.message, { variant: 'error' })
      }
      setLoading(false)
    }

    fetchData()
  }, [])

  const handleSelectedProfile = async (profile: Profile) => {
    setLoading(true)
    const response = await setRole(profile.key, profile.company?.cnpj)

    if (response.data) {
      localStorage.setItem('@ghm:token', response.data)
      updateState()
      history.push('/')
    } else {
      enqueueSnackbar(response.message, { variant: 'error' })
    }

    setLoading(false)
  }

  const generateNameInitials = (profile: Profile) => {
    const name = profile.company ? profile.company.name : profile.description
    const regex = /^(\w+)$/

    if (name && !name.match(regex)) {
      const nameProperty = name.split(/\s+/)
      const first = nameProperty.join('').slice(0, 1)
      const last = nameProperty.pop().slice(0, 1)
      return `${first}${last}`
    }
    if (name && name.match(regex)) {
      const nameProperty = name.split(/\s+/)
      const first = nameProperty.join('').slice(0, 2)
      return `${first}`
    }

    return ''
  }

  if (loading) {
    return <Loader full={true} />
  }

  return (
    <ProfileContainer>
      <FormProfileContainer>
        <div>
          <LogoContainer>
            <LogoSlogan />
          </LogoContainer>
          <Typography variant="body1" color="textPrimary" align="center">
            Selecione o perfil que deseja acessar:
          </Typography>
          <ListProfileContainer>
            {profiles.map((item: Profile, index: number) => (
              <>
                <ListItemProfileContainer
                  button
                  onClick={() => handleSelectedProfile(item)}
                >
                  <ListItemAvatar>
                    <AvatarProfile
                      alt="Avatar da empresa"
                      src={item?.company?.logoPrimary}
                      variant="circle"
                    >
                      {generateNameInitials(item)}
                    </AvatarProfile>
                  </ListItemAvatar>
                  <ListItemText>
                    <Typography>
                      <strong>{item?.company?.name}</strong>
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary">
                      Perfil {item.description}
                    </Typography>
                  </ListItemText>
                  <ProfileIconContainer>
                    <ChevronRight />
                  </ProfileIconContainer>
                </ListItemProfileContainer>
                {index !== profiles.length - 1 && (
                  <Divider variant="inset" component="li" />
                )}
              </>
            ))}
          </ListProfileContainer>
        </div>
      </FormProfileContainer>
      <FooterContainer>
        <ButtonProfileBack onClick={() => history.goBack()}>
          <ChevronLeft />
          <Typography>Voltar para login</Typography>
        </ButtonProfileBack>
      </FooterContainer>
    </ProfileContainer>
  )
}

export default ProfilePage
