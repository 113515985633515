import styled, { css } from 'styled-components'
import { MenuItem } from '@material-ui/core'

export const ActionMenuItem = styled(MenuItem)`
  .item-container {
    display: flex;
    align-items: center;
    min-width: ${(props) => props.theme.spacing(20)}px;
  }
  .icon {
    color: inherit;
    margin-right: ${(props) => props.theme.spacing(3 / 2)}px;
  }
  .text {
    ${(props) => {
      const textStyle = props.theme.typography.body1 as Record<
        string,
        string | number
      >
      return css({ ...textStyle })
    }};
  }
`
